import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: '8.5rem',
      paddingBottom: '8.5rem',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '40% 48%',
    },
    gridColumnGap: '12%',
    alignItems: 'center',
  },
  gridCenter: {
    display: 'grid',
    gridColumnGap: '12%',
    alignItems: 'center',
  },
  gridLeft: {
    display: 'grid',
    gridColumnGap: '12%',
    alignItems: 'left',
  },
  gridInverse: {
    gridTemplateColumns: '100%',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '48% 40%',
    },
  },
}));

export default function Section({
  children,
  inverse,
  className,
  center,
  left,
}) {
  const classes = useStyles();
  let cls = classes.grid;
  if (center) {
    cls = classes.gridCenter;
  } else if (left) {
    cls = classes.gridLeft;
  }

  return (
    <section className={classNames(classes.section, className)}>
      <Container maxWidth="lg" component="main" className={classes.main}>
        <div className={classNames(cls, inverse && classes.gridInverse)}>
          {children}
        </div>
      </Container>
    </section>
  );
}
