import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import Section from './Section';
import Calendar from './Calendar';
import CallToAction from './CallToAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 4vw',
    minHeight: 'unset',
    [theme.breakpoints.up('md')]: {
      minHeight: '50vh',
    },
  },
  container: {
    padding: 0,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '55% 40%',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    gridColumnGap: '5%',
  },
  title: {
    letterSpacing: 2,
    margin: '25px 0',
    fontSize: 32,
    [theme.breakpoints.up('md')]: {
      margin: '50px 0',
      fontSize: 56,
    },
  },
  subtitle: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },
  },
  callToAction: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },
    gridColumn: '1/-1',
  },
}));

export default function Hero(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" component="main" className={classes.container}>
        <div className={classes.grid}>
          <Typography className={classes.title} variant="h1">
            Sheffield Consulting is a premier value creating advisor.
          </Typography>
        </div>
      </Container>
    </div>
  );
}
