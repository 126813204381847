import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import Section from './Section';

const useStyles = makeStyles((theme) => ({
  callToAction: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  actionText: {
    marginTop: theme.spacing(2),
  },
}));

export default function CallToAction({ className, action }) {
  const classes = useStyles();

  return (
    <div className={className}>
      <div className={classes.callToAction}>
        <Typography variant="h4">
          {action ? action : 'Success through the Sheffield Advantage'}
        </Typography>

        <Typography className={classes.actionText} variant="subtitle1">
          Contact 646-801-5808
        </Typography>
      </div>
    </div>
  );
}
