import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Image from './Image';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 4vw',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(8),
    },
  },
  container: {
    padding: 0,
  },
  header: {
    textAlign: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
  },
  quote: {
    paddingTop: theme.spacing(2),
  },
}));

export default function Hero(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.header} variant="h3">
        A few of the companies Sheffield has worked with.
      </Typography>
      <Container maxWidth="lg" component="main" className={classes.container}>
        <Image src="companies.png" />
      </Container>
    </div>
  );
}
