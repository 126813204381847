import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';

import Image from './Image';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    order: 1,
    flexBasis: '49%',
  },
  imageInverse: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 0,
    },
    flexBasis: '49%',
  },
  title: {
    textAlign: 'left',
    marginBottom: '1rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: '2.5rem',
    },
  },
  description: {
    marginBottom: '1rem',
  },
}));

export default function Poster({
  title,
  description,
  description2,
  description3,
  src,
  inverse,
}) {
  const classes = useStyles();
  return (
    <>
      <div
        className={classNames(
          inverse ? classes.imageInverse : classes.imageContainer,
        )}
      >
        <Image src={src} />
      </div>
      <div>
        <Typography className={classes.title} variant="h3">
          {title}
        </Typography>
        <Typography
          className={classes.description}
          variant="body1"
          component="p"
        >
          {description}
        </Typography>
        <Typography
          className={classes.description}
          variant="body1"
          component="p"
        >
          {description2}
        </Typography>
        <Typography
          className={classes.description}
          variant="body1"
          component="p"
        >
          {description3}
        </Typography>
      </div>
    </>
  );
}
