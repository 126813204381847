import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ChatIcon from '@material-ui/icons/Chat';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  supportContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  calendar: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: 320,
    height: 200,
    overflow: 'hidden',
  },
  day: {
    position: 'absolute',
    width: 90,
    padding: '50px 0px',
    textAlign: 'center',
    margin: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    opacity: 0,
  },
  dayText: {
    border: '1px solid white',
    marginTop: theme.spacing(1),
    padding: 6,
    color: 'white',
  },
  arrow: {},
  '@keyframes myEffect': {
    '0%, 25%': {
      transform: 'translate(-40px, 0px)',
      opacity: 0,
    },
    '53%': {
      transform: 'translate(0px, 0px)',
      opacity: 1,
    },
    '99%': {
      transform: 'translate(0px, 120px)',
      opacity: 1,
    },
    '100%': {
      transform: 'translate(0px, 120px)',
      opacity: 0,
    },
  },
  '@keyframes move': {
    '0%': {
      transform: 'translate(440px, 0px)',
    },
    '15%': {
      transform: 'translate(330px, 0px)',
      opacity: 0,
    },
    '30%': {
      transform: 'translate(220px, 0px)',
      color: 'white',
      opacity: 0.5,
    },
    '40%': { color: 'white' },
    '42%': { color: theme.palette.secondary.main },
    '44%': { color: 'white' },
    '45%': {
      transform: 'translate(110px, 0px)',
      color: 'white',
      opacity: 1,
    },
    '60%': {
      color: 'white',
      opacity: 0.5,
      transform: 'translate(0px, 0px)',
    },
    '75%': {
      transform: 'translate(-110px, 0px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translate(-220px, 0px)',
    },
  },
  '@keyframes shake': {
    '41%, 49%, 59%': {
      transform: 'translate3d(0px, 0, 0)',
    },
    '43%, 51%': {
      transform: 'translate3d(2px, 0, 0)',
    },
    '45%, 53%': {
      transform: 'translate3d(-4px, 0, 0)',
    },
    '47%, 56%': {
      transform: 'translate3d(4px, 0, 0)',
    },
    '40%, 60%': {
      color: 'white',
    },
    '49%, 55%': {
      color: theme.palette.secondary.main,
    },
  },
  animatedItem: {
    animation: `$myEffect 3s ${theme.transitions.easing.easeInOut} infinite`,
    position: 'absolute',
    height: 50,
    width: 50,
    marginTop: 20,
    [theme.breakpoints.up('md')]: {
      marginTop: 30,
    },
  },
  move: {
    animation: '$move 21s infinite',
  },
  support: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },
    marginBottom: theme.spacing(2),
    animation: '$shake 3s cubic-bezier(.36,.07,.19,.97) infinite',
  },
}));

export default function Calendar(props) {
  const classes = useStyles();

  return (
    <div className={classes.supportContainer}>
      <Typography variant="h3" className={classes.support}>
        @devs
      </Typography>
      <ChatIcon className={classNames({ [classes.animatedItem]: true })} />
      <div className={classes.calendar}>
        {[
          { day: 'Mon', name: '@paul', delay: '-6s' },
          { day: 'Tue', name: '@jacob', delay: '-3s' },
          { day: 'Wed', name: '@sarah', delay: '0s' },
          { day: 'Thu', name: '@spencer', delay: '3s' },
          { day: 'Fri', name: '@ava', delay: '6s' },
          { day: 'Sat', name: '@dan', delay: '9s' },
          { day: 'Sun', name: '@ryan', delay: '12s' },
        ].map((elem, index) => {
          return (
            <div
              key={elem.day}
              className={classNames(classes.day, classes.move)}
              style={{ animationDelay: elem.delay }}
            >
              <Typography variant="body1">{elem.name}</Typography>
              <Typography variant="body1" className={classes.dayText}>
                {elem.day}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
}
