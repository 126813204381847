import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Section from './Section';
import Poster from './Poster';

const useStyles = makeStyles((theme) => ({
  root: {},
  driving: {
    paddingTop: '1.5rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: '8.5rem',
    },
    textAlign: 'left',
  },
  drivingSection: {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    [theme.breakpoints.up('md')]: {
      paddingBottom: '8.5rem',
      paddingTop: '3.5rem',
    },
  },
  drivingText: {
    marginBottom: 16,
  },
  greySection: {
    backgroundColor: '#f5f5f5',
  },
}));

export default function Content(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg" component="main" className={classes.main}>
        <Typography className={classes.driving} variant="h2">
          Driving Success
        </Typography>
      </Container>
      <Section className={classes.drivingSection}>
        <div>
          <Typography variant="body1" className={classes.drivingText}>
            Indentify improvement opportunities
          </Typography>
          <Typography variant="body1" className={classes.drivingText}>
            Increase stakeholders' rewards and returns
          </Typography>
        </div>
        <div>
          <Typography variant="body1" className={classes.drivingText}>
            Implement strategies and plans
          </Typography>
          <Typography variant="body1" className={classes.drivingText}>
            Enhance business
          </Typography>
        </div>
      </Section>
      <Section className={classes.greySection}>
        <Poster
          title="The Sheffield Alliance"
          description="Maintain benefits of a partnership with Sheffield Consulting through achievement of your company’s goals and success."
          description2="We do things right – driving success for our clients through careful identification and implementation of strategies and plans – increasing returns for shareholders and owners – investing in employees and the future – improving the surrounding environment and communities – respecting cultures and expanding social awareness."
          src="cytonn-photography-vWchRczcQwM-unsplash.jpg"
        />
      </Section>
      <Section inverse>
        <Poster
          title="Gain the Sheffield Advantage"
          description="People and Perspective – Build a world-class team."
          description2="Plans – Explore innovative strategies and develop sustainable growth actions."
          description3="Project and Production – Implement continual improvement and maximizing value techniques."
          src="josh-calabrese-Ca8r0PSWg9Q-unsplash.jpg"
          inverse
        />
      </Section>
    </div>
  );
}
