import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Layout from '../components/Layout';
import Hero from '../components/hero';
import Content from '../components/Content';
import CallToAction from '../components/CallToAction';
import Testimonials from '../components/Testimonials';
import SEO from '../components/seo';
import Section from '../components/Section';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  main: {
    padding: '0 4vw',
  },
  container: {},
}));

export default function TellSpin(props) {
  const classes = useStyles();
  return (
    <Layout>
      <SEO title="Sheffield Business Consulting" />
      <Hero />
      <Content />
      <Testimonials />
      <Section left>
        <CallToAction className={classes.callToAction} />
      </Section>
    </Layout>
  );
}
